<template>
  <div>
    <div class="text-end mb-3 pt-3">
      <button class="btn btn-inline" @click="loadCreate()"
              v-show="userData.roleId == 2">
        <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
      </button>
    </div>
    <div class="text-center" v-if="sessions.length == 0">
      <h4>No sessions available at the moment</h4>
    </div>
    <div class="mb-3" v-for="sess, ind in sessions" :key="ind">
      <div class="accordion" :id="`accordionTasks${ind}`">
        <div class="accordion-item">
          <h4 class="accordion-header accordion-title" :id="`publishedTitle${ind}`">
            <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#publishedTable${ind}`" aria-expanded="true"
                    aria-controls="publishedTable">
              {{sess.topic}}
            </button>
          </h4>
          <div :id="`publishedTable${ind}`" class="accordion-collapse collapse" disabled
               :aria-labelledby="`publishedTitle${ind}`" :data-bs-parent="`#accordionTasks${ind}`">
            <div class="bg-white p-0 accordion-card rounded shadow">
              <div class="row mx-0 py-3">
                <div class="col-md-9 d-flex flex-row justify-content-evenly mx-0">
                  <div class="text-center">
                    <h4 class="detail-number">{{ sess.start_time | localDate }}</h4>
                    <p class="detail-text">Scheduled At</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ sess.duration }}</h4>
                    <p class="detail-text">Duration (Min)</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ sess.participants.split(',').length }}</h4>
                    <p class="detail-text">Students</p>
                  </div>
                </div>
                <div class="col-md-3 text-end" v-if="userData.roleId == 2 && isSessionValid(sess)">
                  <button class="btn btn-line-dark" @click="startSession(sess)">
                    <i class="material-icons align-middle"> launch </i>
                  </button>
                  <button class="btn btn-line-dark">
                    <i class="material-icons align-middle"
                       @click="loadEdit(sess)"> edit </i>
                  </button>
                  <button class="btn btn-line-danger">
                    <i class="material-icons align-middle"
                       @click="deleteSession(sess)"> delete </i>
                  </button>
                </div>
                <div class="col-md-3 text-end" v-else-if="isSessionValid(sess)">
                    <button class="btn btn-line-dark" @click="startSession(sess)">
                      <i class="material-icons align-middle"> launch </i>
                    </button>
                </div>
                <div class="col-md-3 text-end" v-else>
                  <h4><span class="badge bg-danger">Session Expired</span></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';

// dayjs.extend(utc);

export default {
  name: 'CourseSessionsList',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      courseId: null,
      sessions: [],
      submitNewModule: false,
      selectedModule: null,
      editable: false,
      createMode: false,
    };
  },
  filters: {
    localDate(value) {
      return dayjs.utc(value).format('ddd, MMMM D, YYYY h:mm A');
    },
  },
  methods: {
    loadCreate() {
      this.$parent.selectedSession = {};
      this.createMode = true;
      this.$router.push({ path: this.$route.path, query: { mode: 'create' } });
    },
    isSessionValid(sess) {
      return dayjs().isBefore(dayjs(sess.start_time));
    },
    getSessions() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/sessions/${this.courseId}`)
        .then((response) => {
          this.sessions = response.data;
          this.sessions = this.sessions.sort(
            (a, b) => new Date(a.start_time) - new Date(b.start_time),
          );
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    createSession() {
      this.$store.commit('setLoadScreen', { status: true });
      const moduleData = {
        courseId: this.courseId,
        title: this.newModule,
        seq: this.modules.length,
        role: this.userData.roleId,
      };
      this.submitNewModule = true;
      axios.post('/api/module/create', moduleData)
        .then((response) => {
          this.modules.push({
            id: response.data.moduleId,
            title: this.newModule,
            seq: this.modules.length,
          });
          this.moduleModal.hide();
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.moduleModal.hide();
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    loadEdit(mod) {
      this.$parent.selectedSession = mod;
      this.$router.push({ path: this.$route.path, query: { mode: 'edit' } });
    },
    startSession(sess) {
      window.open(sess.join_link);
    },
    deleteSession(sess) {
      Swal.fire({
        title: 'Are you sure you want to delete this session?',
        text: 'All associated content will be deleted in 24 hours',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('setLoadScreen', { status: true });
            axios.delete(`/api/session/${sess.id}`)
              .then(() => {
                this.$store.commit('setLoadScreen', { status: false });
                this.getSessions();
              })
              .catch((err) => {
                this.$store.commit('setLoadScreen', { status: false });
                console.log(err);
              });
          }
        });
    },
    getCourseInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.courseId}`, {
        params: {
          role: this.userData.roleId,
          user: this.userData.id,
        },
      })
        .then((response) => {
          this.$store.commit('setLoadScreen', { status: false });
          this.editable = !response.data.published;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.getSessions();
    this.getCourseInfo();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.accordion-item {
  background-color: transparent;
  border:  none;
}
.accordion-title button{
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(0,33,71,1) 60%,
  rgba(0,62,109,1) 75%, rgba(0,103,165,1) 90%);
}
/*.accordion-card{
  max-height: 23em !important;
  overflow-y: scroll;
}*/
.accordion-button:not(.collapsed) {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}
.accordion-button {
  border-radius: 0.5rem !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.detail-number {
  color:  var(--accent-1);
}
.detail-text {
  color:  var(--dark-color);
  font-weight: bold;
  margin-bottom: 0;
}

</style>
