<template>
  <div style="padding-top: 2rem" class="info-container p-0 mx-auto">
    <div>
      <CourseSessionDetails v-if="selectedSession !== null" :selected="selectedSession">
      </CourseSessionDetails>
      <CourseSessionsList v-else></CourseSessionsList>
    </div>
  </div>
</template>
<script>
import CourseSessionsList from './CourseSessionsList.vue';
import CourseSessionDetails from './CourseSessionDetails.vue';

export default {
  name: 'SessionsHome',
  components: {
    CourseSessionDetails,
    CourseSessionsList,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      selectedSession: null,
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1400px;
}
</style>
