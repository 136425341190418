<template>
  <div>
    <button class="btn mb-2 text-primary" @click="$parent.selectedSession = null"> Go back </button>
    <div v-if="!loadScreen">
      <div class="card left-accent-card course-create-content" >

        <div class="card-body">
          <div class="col text-left container col-md-6">
            <h3 class="card-title mb-4" v-if="editMode">Edit Session</h3>
            <h3 class="card-title mb-4" v-else>Create Session</h3>
          </div>
          <form @submit.prevent class="container col-md-6">
            <div class="form-group">
              <label for="sessionProvider" class="form-label">Session Provider</label>
              <select class="form-select" aria-label="Select session provider" id="sessionProvider"
              v-model="newSession.provider" :disabled="editMode"
                      :class="{ 'is-invalid': submitted && $v.newSession.provider.$error }"
              >
                <option selected>Select session provider</option>
                <option v-for="(provider, index) in sessionProviders" v-bind:value="provider"
                        :key="index">
                  {{ provider.toUpperCase() }}
                </option>
              </select>
              <div v-if="submitted && !$v.newSession.provider.required"
                   class="form-text error">Session provider is required
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="sessionTitle" class="form-label">Session Topic</label>
              <input type="text" class="form-control" id="sessionTitle"
                     v-model="newSession.topic" required
                     :class="{ 'is-invalid': submitted && $v.newSession.topic.$error }"
              >
              <div v-if="submitted && !$v.newSession.provider.required"
                   class="form-text error">Session topic is required
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="sessionAgenda" class="form-label">Session Agenda</label>
              <textarea type="text" class="form-control" id="sessionAgenda"
                        v-model="newSession.agenda"></textarea>
            </div>
            <div class="form-group mt-4 col-md-6">
              <label for="sessionStart" class="form-label">Session Start</label>
              <input type="datetime-local" class="form-control" id="sessionStart"
                     v-model="newSession.start_time" required
                     :class="{ 'is-invalid': submitted && $v.newSession.start_time.$error }"
              >
              <div v-if="submitted && !$v.newSession.start_time.required"
                   class="form-text error">Session start time is required
              </div>
            </div>
            <div class="form-group mt-4 col-md-6">
              <label for="sessionDuration" class="form-label">Session Duration (Min)</label>
              <input type="text" class="form-control" id="sessionDuration"
                     v-model="newSession.duration" required
                     :class="{ 'is-invalid': submitted && $v.newSession.duration.$error }"
              >
              <div v-if="submitted && !$v.newSession.duration.required"
                   class="form-text error">Session duration is required
              </div>
              <div v-if="submitted && !$v.newSession.duration.minValue"
                   class="form-text error">Session duration can not be zero
              </div>
            </div>
            <div v-if="message">
              <hr>
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{{ message }}!</strong>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="mt-5 text-end">
        <div v-if="editMode">
          <button type="button" class="float-end btn btn-primary"
                  @click="updateSession()">
            Save changes
          </button>
          <button type="button" class="float-end btn btn-primary me-2"
                  @click="syncSessionParticipants()">
            Sync participants
          </button>
          <button type="button" class="float-end btn btn-secondary me-2"
                  @click="$parent.selectedSession = null">Cancel</button>
        </div>
        <div v-else>
          <button type="button" class="btn btn-primary ms-2" @click="createSession()"
                  >Create Session</button>
          <button type="button" class="btn btn-secondary ms-2"
                  @click="$parent.selectedSession = null">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import dayjs from 'dayjs';
import {
  required, minValue,
} from 'vuelidate/lib/validators';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'CourseSessionDetails',
  props: ['selected'],
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
    editMode() {
      return this.$route.query.mode === 'edit';
    },
  },
  data() {
    return {
      editing: false,
      courseId: null,
      activeItem: null,
      editable: false,
      userInfo: null,
      sessionProviders: [],
      courseMembers: [],
      submitted: false,
      newSession: {
        provider: '',
        topic: '',
        agenda: '',
        start_time: '',
        duration: 0,
        user_id: '',
        participants: [],
        course_id: '',
      },
      message: '',
    };
  },
  validations: {
    newSession: {
      provider: {
        required,
      },
      topic: {
        required,
      },
      start_time: {
        required,
      },
      duration: {
        required,
        minValue: minValue(1),
      },
    },
  },
  methods: {
    getSessionProviders() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/session-providers')
        .then(({ data }) => {
          this.$store.commit('setLoadScreen', { status: false });
          this.sessionProviders = data;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    createSession() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('setLoadScreen', { status: true });
      this.newSession.user_id = this.userData.email;
      this.newSession.start_time = dayjs.utc(this.newSession.start_time);
      this.newSession.course_id = parseInt(this.courseId, 10);
      this.newSession.duration = parseInt(this.newSession.duration, 10);
      this.newSession.participants = this.courseMembers;

      axios.post('/api/session', this.newSession)
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.$parent.selectedSession = null;
        }).catch((error) => {
          this.message = error.response.data.message;
        });
    },
    updateSession() {
      this.$store.commit('setLoadScreen', { status: true });
      this.newSession.course_id = parseInt(this.courseId, 10);
      this.newSession.duration = parseInt(this.newSession.duration, 10);
      this.newSession.participants = this.newSession.participants.split(',');

      axios.post(`/api/session/${this.newSession.id}`, {
        topic: this.newSession.topic,
        agenda: this.newSession.agenda,
        start_time: dayjs.utc(this.newSession.start_time),
        participants: this.newSession.participants,
        duration: this.newSession.duration,
      })
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.$parent.selectedSession = null;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    syncSessionParticipants() {
      this.$store.commit('setLoadScreen', { status: true });
      this.newSession.course_id = parseInt(this.courseId, 10);
      this.newSession.duration = parseInt(this.newSession.duration, 10);
      this.newSession.participants = this.courseMembers;

      axios.post(`/api/session/${this.newSession.id}`, {
        topic: this.newSession.topic,
        agenda: this.newSession.agenda,
        start_time: this.newSession.start_time,
        participants: this.newSession.participants,
        duration: this.newSession.duration,
      })
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.$parent.selectedSession = null;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    getCourseInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.courseId}`, {
        params: {
          role: this.userData.roleId,
          user: this.userData.id,
        },
      })
        .then((response) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(response.data);
          this.editable = !response.data.published;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    getMembers() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/course/getMembers', {
        params: {
          cId: this.courseId,
          role: this.userData.roleId,
        },
      })
        .then(({ data }) => {
          this.courseMembers = data.studentList.map((student) => student.email);
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.courseId = this.$route.params.cid;

    if (this.editMode) {
      const session = this.$parent.selectedSession;
      this.newSession = this.$parent.selectedSession;
      [this.newSession.provider] = session.id.split('_');
      this.newSession.start_time = dayjs.utc(this.newSession.start_time)
        .format('YYYY-MM-DDThh:mm');
    }

    this.getMembers();
    this.getSessionProviders();
    this.getCourseInfo();
  },
};
</script>
<style scoped>

</style>
